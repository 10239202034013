import React, { useContext } from 'react'
// import { NavigationButtons } from '../../Components'
import './contactUs.scss'
import Form from './Form'
import Info from './Info'
import DataContext from '../../Context/DataContext';
import { HiOutlineX } from "react-icons/hi";
const ContactUs = () => {

  const data = useContext(DataContext);
  const contactToMenu = data.contactToMenu;
  const setContactToMenu = data.setContactToMenu;
  const popUpDiv = data.popUpDiv;
  const swiperMainController = data.swiperMainController;
    const removeContact = () =>{
      if (!popUpDiv) {
        swiperMainController("enable")
      }
      const contactToMenuDiv = document.querySelector<HTMLInputElement>(`#${contactToMenu}`)
      if (contactToMenuDiv) {
        contactToMenuDiv?.classList.add('close')
          setTimeout(() => {
            contactToMenuDiv.style.display = "none"
        }, 500);
        setContactToMenu("")
      }
    }

  return (
    <div id='ContactUs' className='close'>
          {/* <div className="left">
          <NavigationButtons/>
        </div> */}
        <div className="outer">
          <div className="inner">
          <div className="cross"> <div className="crossButton" onClick={()=>removeContact()}><HiOutlineX/></div></div>
          <div className="realContactUsDiv">
            <div className="innerTop">
            <div className="subtitle">चाय हमारी, बिल तुम्हारा</div>
            <div className="heading">How can we help you?</div>
            </div>
          <div className="form">
            <div className="formUpper">
            <div className="title">Get in touch with us!</div>
            <hr />
            </div>
            <div className="lower">
            <div className="left">
              <Form/>
            </div>
            <div className="right">
              <Info/>
            </div>
            </div>
          </div>
          </div>
          
          </div>
        </div>
    </div>
  )
}

export default ContactUs