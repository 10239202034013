import React, { useContext, useState } from 'react'
import * as emailjs from "emailjs-com";

import DataContext from '../../Context/DataContext';

const Form = () => {
  const contextData = useContext(DataContext);
  const formData = contextData.formData;
  const setFormdata = contextData.setFormdata;

  const contactConfig = {
    YOUR_EMAIL:"contact@h3abizsol.com",
    YOUR_SERVICE_ID:"service_e1hekno",
    YOUR_TEMPLATE_ID:"template_bl4j3oq",
    YOUR_USER_ID:"CZDfnH_D-h1GchvAJ",
  }
  const handleSubmit = (e:any) => {
    e.preventDefault();
    setFormdata({ loading: true });

  const templateParams = {
    from_email: formData.email,
    user_name: formData.name,
    to_email: contactConfig.YOUR_EMAIL,
    message: formData.message,
  };

    emailjs
      .send(
        contactConfig.YOUR_SERVICE_ID,
        contactConfig.YOUR_TEMPLATE_ID,
        templateParams,
        contactConfig.YOUR_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormdata({
            loading: false,
            // alertmessage: "SUCCESS! ,Thankyou for your messege",
            alertmessage: `Thank you ${templateParams.user_name}. Our team will shortly contact you.`,
            variant: "success",
            show: true,
          });
        },
        (error) => {
          console.log(error.text);
          setFormdata({
            alertmessage: `Faild to send!,${error.text}`,
            variant: "danger",
            show: true,
          });
          document.getElementsByClassName("co_alert")[0].scrollIntoView();
        }
      );
  };

  const handleChange = (e:any) => {
    setFormdata({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  return (
    <form onSubmit={handleSubmit} >
              <label htmlFor="Your Name">Your Name *</label>
              <input type="text" placeholder='Type your name' name='name' value={formData.name || ""} onChange={handleChange} required/>
              <label htmlFor="Your E-Mail">Your E-Mail *</label>
              <input type="email" placeholder='Type your E-mail address' name='email' value={formData.email || ""} onChange={handleChange} required/>
              <label htmlFor="What's up?">What's up?</label>
              <input type="text" placeholder='Tell us if you need any help' name='message' value={formData.message || ""} onChange={handleChange} />
              <button disabled={formData.loading} >{formData.loading ? "Submiting..." : "Submit"}</button>
              <p className="alertMessage">{formData.alertmessage}</p>
    </form>
  )
}

export default Form