// import logo from '../../Assets/Images/logo.svg';

import React, { useContext, useEffect, useRef } from 'react'
import Helmet from "react-helmet";
import './home.scss'
import gsap from 'gsap'; 
// import SwiperDiv from '../../Components/Swiper/Swiper';
import LoweHome from '../../Components/LoweHome/LoweHome';
import Navbar from '../../Components/Navbar/Navbar';
import SwiperSection from '../../Pages/SwiperSection/SwiperSection';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
// import { Swiper, SwiperSlide } from "swiper/react";
import Swiper from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "../Test/test.scss";
// import "./test.scss";

// import required modules
import { EffectCreative,Pagination,Mousewheel,Keyboard   } from "swiper";

import DataContext from '../../Context/DataContext';
import Switch from '../../Components/Switch/Switch';


const Home = () => {
  const contextData = useContext(DataContext);
  gsap.registerPlugin(ScrollTrigger)

    const timeline = gsap.timeline();
    const navRef = useRef<HTMLDivElement>(null);
    const leftDivRef = useRef<HTMLDivElement>(null);
    const rightDivRef = useRef<HTMLDivElement>(null);
    const logoRef = useRef(null);

    /// Timeline Delay

    // const timelineTimings = {
    //   logoDuration : 1,
    //   logoEyeCrossFade : .5,
    //   eyeDelay : .93,
    //   lastDelay : 1.5,
    //   logodelay : .3,
    //   circleDelay : .3,
    // }
    const timelineTimings = {
      logoDuration : 1,
      logoEyeCrossFade : .5,
      eyeDelay : .25,
      lastDelay : 1.5,
      logodelay : .3,
      circleDelay : .3,
    }
    // const timelineTimings = {
    //   logoDuration : 0,
    //   logoEyeCrossFade : 0,
    //   eyeDelay : 0,
    //   lastDelay : 0,
    //   logodelay : 0,
    //   circleDelay : 0,
    // }

  //   useEffect(()=>{
  //     gsap.utils.toArray(".innerGo").forEach((panel:any ,i)=>{
  //        ScrollTrigger.create({
  //           trigger:panel,
  //           start: "top top",
  //           pin:true,
  //           pinSpacing: false
  //        })
  //     })
  //     if(true){
  //     let section:any = gsap.utils.toArray('.containerPlusdd');
  //     // let section:any = gsap.utils.toArray('.containerPlus');
  //     let contain:any = document.querySelector('.containerPlus')
  //     if(section){

  //     gsap.to(section,{
  //        ease:"none",
  //        scrollTrigger:{
  //           trigger: ".containerPlus",
  //           pin:"true",
  //           // scrub:0,
  //           snap:1/(section.length - 1 ),
  //           id:"snap",
  //         start: ()=>"+=" + (-(100) ),
  //         // end: ()=>"+=" + (contain.offsetHeight+100 ),
  //         end: ()=>"+=" + (contain.offsetHeight+99 ),
  //       }
        
  //     })

  //   // }
        
  //     // ///////         Extra             ////////
  //     // gsap.to(section,{
  //     //   //  xPercent: -100 * (section.length -1),
  //     //    ease:"none",
  //     //    scrollTrigger:{
  //     //       trigger: ".containerPlus",
  //     //       pin:"true",
  //     //       scrub:.1,
  //     //       snap:1/(section.length - 1 ),
  //     //       // snap:-.9,
  //     //       id:"snap",
  //     //       markers:true,
  //     //     // start: ()=>"+=" +
  //     //     // (-(100) ),
  //     //     // start: ()=>"-=" +(200 ),
  //     //     // start: 100,
  //     //     // start: ()=>"+=" + (-(100) ),
  //     //     // end: ()=>"+=" + (contain.offsetHeight+100 ),
  //     //   //   // end: ()=>"+=" +
  //     //   //   // (+1000)
  //     //   // },
  //     //     // end: ()=>"+=" +
  //     //     // (contain.offsetHeight-100 ),
  //     //     // end: ()=>"+=" +
  //     //     // (+1000)
  //     //     // start: ()=>"+=" + (-(100) ),
  //     //     // end: ()=>"+=" + (contain.offsetHeight+100 ),
  //     //     // end: ()=>"+=" + (contain.offsetHeight+99 ),
  //     //     start: ()=>"+=" + (-(100)),
  //     //     end: ()=>"+=" + (contain.offsetHeight+99 ),
  //     //   }
        
  //     // })
  //   }
  // }
    
  //     // gsap.to('#upperHome',{
  //     //   scrollTrigger:{
  //     //     scrub : 5,
  //     //     markers:true,
  //     //   },
  //     //   marginTop : 100
  //     // })
  //     gsap.to('#upperHome',{
  //       scrollTrigger:{
  //         scrub : .3,
  //         // markers:true,
  //       },
  //       marginTop : -100
  //     })
  //  }, [])

  useEffect(() => {
  // alert(mouseWheel)
  var swiper = new Swiper('#HomePaginationMain', {
    modules:[EffectCreative,Pagination,Mousewheel,Keyboard ],
    // grabCursor:true,
    hashNavigation: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable:true,
    },
    // pagination:{clickable: true,},
    direction:"vertical",
    effect:"creative",
    mousewheel:true,
    keyboard:true,
  //   keyboard: {
  //     enabled: true,
  //     onlyInViewport: false,
  //   },
    creativeEffect:{
      prev: {
        shadow: true,
        translate: ["0%", "-120%", -500],
      },
      next: {
        shadow: true,
        translate: ["0%", "120%", -500],
      },
    },
  })
  if(true) {
    window.history.pushState(null, "null", window.location.href);
    window.onpopstate = function(event) {
      alert("Hello")
      window.history.go(1);
    };
  }
},[])

// Now you can use all slider methods like

return (
  <>

  {/* <div className="OurterGo"> */}
    <Helmet><title>Home</title></Helmet>
    <div  className="swiper" id="HomePaginationMain">
      <div className="swiper-wrapper">
          <div className="swiper-slide" data-hash="slide1">
            <div className="innerGo containerPlus" id='Home'>
              <section  id='upperHome'>
                <Navbar timeline={timeline} navRef={navRef} leftDivRef={leftDivRef} rightDivRef={rightDivRef} logoRef={logoRef} timelineTimings={timelineTimings} />
                <LoweHome timeline={timeline} navRef={navRef} leftDivRef={leftDivRef} rightDivRef={rightDivRef} logoRef={logoRef}  timelineTimings={timelineTimings}/>
              </section>
            </div>
        </div>
          <div className="swiper-slide" data-hash="slide2">
          <div className="innerGo color containerPlus">
              <section>
              <SwiperSection/>
              </section>
          </div>
      </div>
      </div>
      <div className="swiper-pagination"></div>
    </div>
    {/* <Switch/> */}
  {/* </div> */}
  </>
)

}


export default Home