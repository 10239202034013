import React, { useContext, useEffect, useState } from 'react'
import './portfolioFilter.scss'
import {HiArrowSmLeft,HiArrowSmRight} from 'react-icons/hi'
import Swiper from 'swiper';
import data2 from "../../Assets/data2.json"
import noPost from "../../Assets/noPost.png";
import noPost2 from "../../Assets/noPost.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';


// import required modules
import { Navigation,Pagination,Scrollbar,Keyboard,Mousewheel,Autoplay,EffectFade,EffectCoverflow  } from 'swiper';
import DataContext from '../../Context';
import Box from './Box';

const PortfolioFilter = () => {

  const contextData = useContext(DataContext);
  const selectedData = contextData.selectedData;
  const oneSubCatagory = contextData.oneSubCatagory;
  const setOneSubCatagory = contextData.setOneSubCatagory;
  const activeDiv = contextData.activeDiv;
  const setActiveDiv = contextData.setActiveDiv;
  const noPost = {
    url: "https://firebasestorage.googleapis.com/v0/b/h3a-portfolio.appspot.com/o/Portfolio%2Fdata%2Fnone%2FnoPost.png?alt=media&token=63b717c2-4b25-46bd-b054-2bdecb87ca9d",
    dataType:"img"
  }
    const simple = (elem:string) => elem.toLowerCase().replace(/[^a-zA-Z ]/g, "").replaceAll(/\s/g,'')
    const change = (elem:string) =>{
      if (activeDiv===elem) {
        setActiveDiv("");
        setOneSubCatagory([])
      }else{
        let [oneCategory] = selectedData.categorys.filter((elm:any)=>elm.title===elem).map((elm:any)=> elm.category)
        if (oneCategory[0]===undefined) {
          setOneSubCatagory(null)
        }else{
          setOneSubCatagory(oneCategory);
        }
        setActiveDiv(elem);
      }
        
    }

    useEffect(() => {
        const swiper = new Swiper('#portfolioFilterSwiperContainer', {
            modules:[Navigation,Scrollbar,Keyboard,Autoplay,EffectCoverflow,EffectFade,Pagination ],
            speed: 400,
            //   centeredSlides:true,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            navigation: {
                nextEl: ".portfolioFilterSwiperContainer-next-button",
                prevEl: ".portfolioFilterSwiperContainer-prev-button"
            },
            // loop:true,
            slidesPerView: 1,
            spaceBetween: 10,
            grabCursor:true,
            breakpoints: {
              // when window width is >= 320px
              320: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              // when window width is >= 320px
              550: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              // when window width is >= 480px
              850: {
                slidesPerView: 4,
                spaceBetween: 40
              },
              // when window width is >= 640px
              1080: {
                slidesPerView: 5,
                spaceBetween: 60
              }
            }
          });
      },[])
  let allSubCatagory = selectedData.categorys.map((elm:any)=> elm.category).reduce((total:any,elm:any)=> [...elm, ...total])
  return (
    <div id='PortfolioFilter' className='close'>
        <div className="right">
            <div className="small">Portfolio</div>
            <div className="heading">{selectedData.title}</div>
            <div className="middle">
            <div className="button"> <button className='portfolioFilterSwiperContainer-prev-button' ><HiArrowSmLeft/></button> </div>
                <div className="slider">
                    <div id="portfolioFilterSwiperContainer" className='swiper'>
                        <div className="swiper-wrapper">
                { React.Children.toArray(
                        selectedData.categorys.map((elm:any)=> elm.title).map((elm:any ,i:number)=>{
                            return(
                                <div className="swiper-slide" data-hash={"slide"+(i+1)}>
                                  <div className={ (activeDiv===elm)?`title active`:`title`} onClick={()=>change(elm)} >{elm}</div>
                                </div>
                                // <div className="swiper-slide" data-hash={"slide"+(i+1)}>
                                //   <div className={ (activeDiv===simple(elm))?`title active`:`title`} onClick={()=>change(simple(elm))} >{elm}</div>
                                // </div>
                            )
                        }))
                        }
                        </div>
                        
                    </div>
                </div>
            <div className="button"> <button  className='portfolioFilterSwiperContainer-next-button'><HiArrowSmRight/></button> </div>
            </div>
            <div className="lower">
            <div className="container">
            {
            oneSubCatagory===null ?
            <Box elm={noPost}/>: 
            oneSubCatagory[0]!==undefined ?
              React.Children.toArray(
                oneSubCatagory.map((elm:any)=>{
                  return(
                    <>
                        <Box elm={elm}/>
                      {/* {elm.dataType==="site"&&<Box elm={elm}/>} */}
                      {/* {elm.dataType==="img"&&<Box elm={elm}/>} */}
                      {/* {elm.dataType==="video"&&<Box elm={elm}/>} */}
                      {/* {"img"==="img"&&<Box elm={elm}/>} */}
                    </>
                  )
                })
              ):
             allSubCatagory[0]===undefined ?
                  <Box elm={noPost}/>
                 :
            React.Children.toArray(
              allSubCatagory.map((elm:any)=>{
                return(
                  <>
                      <Box elm={elm}/>
                     {/* {elm.dataType==="site"&&<Box elm={elm}/>} */}
                     {/* {elm.dataType==="img"&&<Box elm={elm}/>} */}
                     {/* {elm.dataType==="video"&&<Box elm={elm}/>} */}
                     {/* {"img"==="img"&&<Box elm={elm}/>} */}
                  </>
                )
              })
            )
          }
                {/* <div className="box"></div> */}
            </div>
            </div>
    </div>
    </div>
  )
}
export default PortfolioFilter