import { ReactNode, useRef, useState } from "react";
import DataContext from "./DataContext";
import mainData from "../Assets/data.json";


const DataState = ({children} : { children : ReactNode}) => {
  const [popUpDiv, setPopUpDiv] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [contactToMenu, setContactToMenu] = useState("");
  const [swiperToSingleProps, setSwiperToSingleProps] = useState(false);
  const [previewPopUpData, setPreviewPopUpData] = useState({});
  const [mouseWheel, setMouseWheel] = useState(true);
  const [oneSubCatagory, setOneSubCatagory] = useState<any>([])
  const [activeDiv, setActiveDiv] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [searchItems, setSearchItems] = useState([])
  const searchDivRef = useRef<HTMLDivElement>(null);
  const [formData, setFormdata] = useState<any>({
    email: "",
    name: "",
    message: "",
    loading: false,
    show: false,
    alertmessage: "",
    variant: "",
  });
  const restSearch = () =>{
    if (searchValue) {
      searchDivRef.current?.classList.remove("open")
      setSearchValue("")
      setSearchItems([])
    }
      setFormdata({
        email: "",
        name: "",
        message: "",
        loading: false,
        show: false,
        alertmessage: "",
        variant: "",
      })
  }
  const swiperMainController = (key:String) =>{
    const swipers:any = document.querySelector('#HomePaginationMain');
      const mainSwiper = swipers.swiper;
        // mainSwiper.allowTouchMove=!mouseWheel;
        switch (key) {
          case "disable":
            mainSwiper.disable()
            break;
          case "enable":
            mainSwiper.enable()
            break;
          case "next":
            mainSwiper.slideNext()
            break;
          case "prev":
            mainSwiper.slidePrev()
            break;
          default:
            break;
        }
  }

  const extractingFunction = () =>{
    let extractingFromArray = mainData.map((firstArrayelem:any)=>{
      const sort = firstArrayelem.categorys.map((secondArrayelem:any) => {
        const combine = {
          title:firstArrayelem.title,
          categorys:secondArrayelem.title,
          productNumber:secondArrayelem.category.length
        }
        return combine
      })
      return sort
    })
    const mergingAllArrayInOne = Array.prototype.concat.apply([], extractingFromArray);
    return mergingAllArrayInOne;
  }
  const allSearchData = extractingFunction();


  return (
    <DataContext.Provider
      value={{
        mainData,
        popUpDiv,
        setPopUpDiv,
        selectedData,
        setSelectedData,
        contactToMenu,
        setContactToMenu,
        swiperToSingleProps,
        setSwiperToSingleProps,
        previewPopUpData,
        setPreviewPopUpData,
        mouseWheel,
        setMouseWheel,
        swiperMainController,
        oneSubCatagory,
        setOneSubCatagory,
        activeDiv,
        setActiveDiv,
        allSearchData,
        searchValue,
        setSearchValue,
        searchItems,
        setSearchItems,
        searchDivRef,
        restSearch,
        formData,
        setFormdata
      }}
    >

      {children}
    </DataContext.Provider>
  );
};
export default DataState;