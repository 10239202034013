import React, { useContext, useRef, useState } from 'react'
import './needToHear.scss'
import { HiOutlineX } from "react-icons/hi";

import DataContext from '../../Context/DataContext';
import NeedToHearSwiper from './NeedToHearSwiper';
import Search from '../../Components/Search/Search';
const NeedToHear = () => {

  const data = useContext(DataContext);
  const contactToMenu = data.contactToMenu;
  const setContactToMenu = data.setContactToMenu;
  const popUpDiv = data.popUpDiv;
  const swiperMainController = data.swiperMainController;

    const removeContact = () =>{
      if (!popUpDiv) {
        swiperMainController("enable")
      }
      const contactToMenuDiv = document.querySelector<HTMLInputElement>(`#${contactToMenu}`)
      if (contactToMenuDiv) {
        contactToMenuDiv?.classList.add('close')
          setTimeout(() => {
            contactToMenuDiv.style.display = "none"
        }, 500);
        setContactToMenu("")
      }
    }


  return (
    <div id='NeedToHear'className='close'>
        <div className="outer">
            <div className="inner">
                <div className="realNeedToHearDiv">
                    <div className="cross"> <div className="crossButton" onClick={()=>removeContact()}><HiOutlineX/></div></div>
                <div className="portfolioLeft">P O R T F O L I O</div>
                <div className="right">
                    <div className="upper">
                    <div className="upperheading">Things that a business needs!</div>
                    <hr />
                      <Search/>
                    </div>
                    <NeedToHearSwiper/>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NeedToHear