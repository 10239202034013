import React, { useContext, useEffect, useRef } from 'react'
import './loweHome.scss'
import {Power4} from 'gsap'
import SocialMediaDiv from "../SocialMedia/SocialMediaDiv"
import Rotater from "../Rotater/Rotater"
import RotaterNine from "../Rotater/RotaterNine"
import { AiOutlineArrowDown } from "react-icons/ai";
import DataContext from '../../Context/DataContext';
import BurgerMenu from '../BurgerMenu/BurgerMenu'
import MobileRotater from '../MobileRotater/MobileRotater'

interface props {
    timeline: gsap.core.Timeline;
    navRef: React.RefObject<HTMLDivElement>;
    leftDivRef: React.RefObject<HTMLDivElement>;
    rightDivRef: React.RefObject<HTMLDivElement>;
    logoRef: React.MutableRefObject<null>
    timelineTimings: {
        logoDuration: number;
        logodelay: number;
        logoEyeCrossFade: number;
        eyeDelay: number;
        circleDelay: number;
        lastDelay: number;
    }
}
const LoweHome = ({timeline,navRef,leftDivRef,rightDivRef,logoRef,timelineTimings }:props) => {
    const contextData = useContext(DataContext);
    const setPopUpDiv = contextData.setPopUpDiv;
    const swiperMainController = contextData.swiperMainController;
    const divNone = useRef<HTMLDivElement>(null);
    const lowerLeft = useRef<HTMLDivElement>(null);
    const innerLowerLeft = useRef<HTMLDivElement>(null);
    const goUpDiv = useRef<HTMLDivElement>(null);
    const lowerRight = useRef<HTMLDivElement>(null);
    const welcomeDiv = useRef<HTMLDivElement>(null);
    const portFoliodiv = useRef<HTMLDivElement>(null);
    const portFolioinnerdiv = useRef<HTMLDivElement>(null);
    const aboutDiv = useRef<HTMLDivElement>(null);
    const eyeSpan1 = useRef<HTMLDivElement>(null);
    const eyeSpan2 = useRef<HTMLDivElement>(null);
    const eyeBlackDot1 = useRef<HTMLDivElement>(null);
    const eyeBlackDot2 = useRef<HTMLDivElement>(null);
    const scrollDownDivRef = useRef<HTMLDivElement>(null);
    const rightDivRefLink = useRef<HTMLDivElement>(null);
    const windowWidth = window.innerWidth;
    
    const tranisionEffect = ()=>{
        const eyeSpan = [eyeSpan1.current,eyeSpan2.current]
        const eyeBlackDot = [eyeBlackDot1.current,eyeBlackDot2.current]

        const {eyeDelay,lastDelay,logoEyeCrossFade,circleDelay} = timelineTimings

        timeline
        //                              #1
        // Portfolio-Entry #1
        .from(portFoliodiv.current, {
            duration: logoEyeCrossFade,
            ease: Power4.easeInOut,
            opacity:0,
        },`-=${logoEyeCrossFade}`)
        
        //                              #2
        // eyeBlackDot-lower-left #2
            .to(eyeBlackDot, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                marginTop:"30%",
                marginRight:"30%",
            })

        //                              #3
        // eyeBlackDot-lower-right #3
            .to(eyeBlackDot, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                // marginTop:"30%",
                marginRight:"-30%",
                delay:eyeDelay
            })

        //                              #4
        // eyeBlackDot-left #4
            .to(eyeBlackDot, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                marginTop:"0%",
                marginRight:"30%",
                delay:eyeDelay
            })
        // eyeSpan-lowheight #4
            .to(eyeSpan, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                height:"50%",
            },`-=${eyeDelay}`)

        //                              #5
        // eyeBlackDot-right #5
             .to(eyeBlackDot, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                marginRight:"-30%",
                delay:eyeDelay
            })

        //                              #6
        // eyeSpan-fullheight #6
            .to(eyeSpan, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                height:"100%",
                delay:eyeDelay
            })
        // eyeBlackDot-center #6
             .to(eyeBlackDot, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                marginRight:"0%",
            },`-=${eyeDelay}`)
        // eye-close #6
            .to(eyeSpan, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                height:"0%",
                delay:eyeDelay
            })
        // eye-open #6
            .to(eyeSpan, {
                duration: eyeDelay,
                ease: Power4.easeInOut,
                height:"100%",
            })

//                                      #7

        //Portfolio-left #7
        .to(portFolioinnerdiv.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            marginLeft:"auto",
        })

        // Logo-Opacity #7 #Logo
        .to(
            logoRef.current, {
                duration: lastDelay,
                opacity: 1, 
                height:"60%",
                // delay:2,
                ease: Power4.easeInOut,
            },`-=${lastDelay}`
        )
        // navBar #7 #Logo
        timeline.to(
            navRef.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            height:"7rem",
        },`-=${lastDelay}`)
        // Left-Div #7 #Logo
        .to(
            leftDivRef.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            width:"8rem",
        },`-=${lastDelay}`)
        // Right-Div #7 #Logo
        .from(
            rightDivRef.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            width:"30vmin",
            top: "-3.5rem",
        },`-=${lastDelay}`)
        
        // welcomeDiv #7
            .from(welcomeDiv.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            opacity: 0,
            marginRight:-100
        },`-=${lastDelay}`)
        // About div #7
        .from(aboutDiv.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            opacity: 0,
            marginRight:-100
            },`-=${lastDelay}`)
        // Circle div #7
        .from(goUpDiv.current, {
            duration: lastDelay-circleDelay,
            ease: Power4.easeInOut,
            opacity: 0,
            // marginTop:150,
            top:400,
            // bottom:-150,
            delay:circleDelay,
            },`-=${lastDelay}`)
        // Social-media div #7
        .from(innerLowerLeft.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            opacity: 0,
            marginLeft:-50,
            },`-=${lastDelay}`)
        // Social-media div #7
        .from(scrollDownDivRef.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            opacity: 0,
            bottom:-100,
            },`-=${lastDelay}`)
        .from(rightDivRefLink.current, {
            duration: lastDelay,
            ease: Power4.easeInOut,
            opacity: 0,
            top:-100,
            },`-=${lastDelay}`)

    }


    interface buttonProps{
        e:any,
        divName:string
    }
  const buttons = ({e,divName}:buttonProps) =>{
       e.target.disabled = true;
       const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
       backdiv?.classList.add('close')
       setTimeout(() => {
           const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
           if (divs) {
               divs.style.display = "flex"
            }
            swiperMainController("next");
            setPopUpDiv(divName)
            setTimeout(() => {
                divs?.classList.remove('close')
                swiperMainController("disable");
                e.target.disabled = false;
            }, 200);
        }, 2000);
  }
  
    useEffect(() => {
        tranisionEffect();

            // eslint-disable-next-line 
    }, [])


  return (
    <div className='lowerHome' >
        <div className="upperSpace">
        <div className="right" ref={rightDivRefLink} >
            <div className="burgerMenu">
                <BurgerMenu/>
            </div>
            <div className="inner">
                <a href="http://h3abizsol.com/" target="_blank" >Website</a>
            </div>
        </div>
        </div>
        <div className="lowerSpace">
            <div className="left" ref={lowerLeft} >
                <SocialMediaDiv innerLowerLeft={innerLowerLeft} />
            </div>
                <div className="goUp" ref={goUpDiv}>
                    {/* <Rotater/> */}
                    {(windowWidth >=500)?<RotaterNine/>:<MobileRotater/>}
                    {/* <div className="rotaterWeb">
                        
                    </div>
                    <div className="rotaterMobile">
                        
                    </div> */}
                    </div>
            <div className="right"  ref={lowerRight} >
                <div className="welcome" ref={welcomeDiv} >
                    <div className="upper">Welcome</div>
                    <div className="lower">to our</div>
                </div>
                <div className="portfolio" ref={portFoliodiv}>
                    <div className="innerPortfolio" ref={portFolioinnerdiv} >
                        PORTF
                        <span><div className="cropper"  ref={eyeSpan1}><div className="circle"><div className="black" ref={eyeBlackDot1} ></div></div></div> </span>LI
                        <span ><div className="cropper" ref={eyeSpan2}><div className="circle"><div className="black" ref={eyeBlackDot2}></div></div></div></span></div>
                    </div>
                <div className="about" ref={aboutDiv}>
                    <div className="upper">
                    We are dedicated to offering services and solutions that are tailored to the individual needs of our customers. It's a one-stop for all of your business's problems.<br/>You can begin your digital journey with us by achieving the highest possible search engine rating. Logo design, website development, ad design, corporate portfolios, and a variety of other services are also available. Individuals with expertise in social media marketing, graphic design, web development, and content writing make up our team.
                    </div>
                    <div className="lower">
                        <button className='knowMoreButton' onClick={(e)=>buttons({divName:"MadeOfIdea",e})} >Know More</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="scrollDownDiv" ref={scrollDownDivRef} >Scroll Down To Continue&nbsp;<span><AiOutlineArrowDown/></span> </div>

    </div>
  )
}

export default LoweHome