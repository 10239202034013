import React, { useContext, useRef, useState } from 'react'
import "./search.scss"
import {FiSearch  } from "react-icons/fi";

import DataContext from '../../Context/DataContext';

const Search = () => {

  const data = useContext(DataContext);
  const contactToMenu = data.contactToMenu;
  const setContactToMenu = data.setContactToMenu;
  const popUpDiv = data.popUpDiv;
  const swiperMainController = data.swiperMainController;
  const allSearchData = data.allSearchData;
  const setSelectedData = data.setSelectedData;
  const setPopUpDiv = data.setPopUpDiv;
  const setOneSubCatagory = data.setOneSubCatagory;
  const setActiveDiv = data.setActiveDiv;
  const mainData = data.mainData;
  const {
    searchValue,
    setSearchValue,
    searchItems,
    setSearchItems,
    searchDivRef,
    restSearch
} = data;
  
    
  const toFilter = (elem:string,option:string) =>{
    restSearch();
    const [newData] = mainData.filter((elm:any)=>elm.title===elem)
    const divName = "PortfolioFilter"
    if (!popUpDiv) {
      swiperMainController("enable")
    }
    const contactToMenuDiv = document.querySelector<HTMLInputElement>(`#${contactToMenu}`)
    if (contactToMenuDiv) {
      contactToMenuDiv?.classList.add('close')
//ADD
      if (option) {
        let [oneCategory] = newData.categorys.filter((elm:any)=>elm.title===option).map((elm:any)=> elm.category)
        if (oneCategory[0]===undefined) {
          setOneSubCatagory(null)
        }else{
          setOneSubCatagory(oneCategory);
        }
        setActiveDiv(option)
      }
      setSelectedData(newData)
      swiperMainController("disable");
      setTimeout(() => {
      const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
      if (divs) {
        divs.style.display = "flex"
      }
      setTimeout(() => {
        divs?.classList.remove('close')
        const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
        backdiv?.classList.add('close')
      }, 100);
      setPopUpDiv(divName)
      }, 100);
//ADD
        setTimeout(() => {
          contactToMenuDiv.style.display = "none"
      }, 500);
      setContactToMenu("")
    }
  }
    

    const change = (e:any)=>{

        const filter = (arr:any, query:any) => {
          return arr.filter(function(el:any) {
            return el.categorys.toLowerCase().indexOf(query.toLowerCase()) !== -1
          })
        }
        if (e.target.value === "") {
          searchDivRef.current?.classList.remove("open")
          setSearchItems([])
        }else{
          searchDivRef.current?.classList.add("open")
          setSearchItems(filter(allSearchData, e.target.value))
        }
        setSearchValue(e.target.value);
      }
      

  return (
    <div id='outerSearch'>
    <div className="search">
    <input type="text" value={searchValue} onChange={change} placeholder='Search here'/>
    <div className="icon"  >
        <FiSearch/>
    </div>
    <div className="searchList" ref={searchDivRef}>
    { React.Children.toArray(
        searchItems.map((elem:any)=>{
        return(
            <div className="itemList" onClick={()=>toFilter(elem.title,elem.categorys)} >
                <div className="searchLeft">
                    <div className="title">{elem.categorys}</div>
                    <div className="category">{elem.title}</div>
                </div>
                <div className="searchRight">
                    <div className="number">{elem.productNumber}</div>
                </div>
            </div>
            )}
        ))}
    </div>
    </div>
    </div>
  )
}

export default Search