import React, { useContext, useRef } from 'react'
import './burgerMenu.scss'
import logo from '../../Assets/Images/logo.svg';
import { MdArrowForwardIos } from "react-icons/md";
import DataContext from '../../Context/DataContext';

interface TestProps {
    navigationWidth?: any,
}
const BurgerMenu = ({navigationWidth}:TestProps) => {

  const contextData = useContext(DataContext);
  const swiperMainController = contextData.swiperMainController;
  const contactToMenu = contextData.contactToMenu;
  const setContactToMenu = contextData.setContactToMenu;
  const popUpDiv = contextData.popUpDiv;
  const mainData = contextData.mainData;
  const setSelectedData = contextData.setSelectedData;
  const setPopUpDiv = contextData.setPopUpDiv;


  const btn = useRef<any>(null);
  const BurgerMenuRef = useRef<HTMLDivElement>(null);
  const innerList = useRef<HTMLDivElement>(null);
  const arrow = useRef<HTMLDivElement>(null);

    const openBurger = () =>{
        // const btn = document.querySelector(".fancy-burger");
        // const btn:any = document.querySelector(".fancy-burger");
        btn.current.querySelectorAll("span").forEach((span:any) => span.classList.toggle("open"));
        BurgerMenuRef.current?.classList.toggle("open");
        innerList.current?.classList.remove("open")
        arrow.current?.classList.remove("open")
        const open:any = BurgerMenuRef.current?.classList
                if (open[1] === "open") {
            swiperMainController("disable");
        }else{
            swiperMainController("enable");
        }
        if (navigationWidth) {
            navigationWidth.current?.classList.toggle("open")
        }
        // e.querySelectorAll("span").forEach((span:any) => span.classList.toggle("open"));
    }
    const removeContact = () =>{
        if (!popUpDiv) {
            swiperMainController("enable")
        }
        const contactToMenuDiv = document.querySelector<HTMLInputElement>(`#${contactToMenu}`)
        if (contactToMenuDiv) {
            contactToMenuDiv?.classList.add('close')
            setTimeout(() => {
                contactToMenuDiv.style.display = "none"
            }, 500);
            setContactToMenu("")
        }
        }
        
    const contactMenu = (divName:string) =>{
        if (contactToMenu !== divName) {
        if(contactToMenu !== "") {
        removeContact()
        }
        swiperMainController("disable")
        setTimeout(() => {
        const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
        if (divs) {
            divs.style.display = "flex"
        }
        setTimeout(() => {
            divs?.classList.remove('close')
        }, 100);
        setContactToMenu(divName)
        }, 100);
    }
    }
    
    const Home = () =>{
        openBurger();

        swiperMainController("prev")
    }
    const Portfolio = () =>{
        // openBurger();
        // swiperMainController("next")
        innerList.current?.classList.toggle("open")
        arrow.current?.classList.toggle("open")

    }
    const Contact = () =>{
        openBurger();
        swiperMainController("next")
        contactMenu("ContactUs")
    }
    
  const buttons = (divName:string, data:any) =>{
    openBurger();
    setSelectedData(data)
    swiperMainController("next")
    swiperMainController("disable");
    setTimeout(() => {
    const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
    if (divs) {
      divs.style.display = "flex"
    }
    setTimeout(() => {
      divs?.classList.remove('close')
      const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
      backdiv?.classList.add('close')
    }, 100);
    setPopUpDiv(divName)
    }, 100);
  }
  return (
    <div id="BurgerMenu">
           <div className="box" ref={BurgerMenuRef}  >
                        <div className="BurgerOuter">
                            <div className="inner">
                                <div className="logoDiv">
                                    <img src={logo} alt="logo" />
                                </div>
                                <div className="title">Welcome</div>
                                <div className="outerList">
                                    <div className="list" onClick={()=>Home()} >Home</div>
                                    <div className="list" onClick={()=>Portfolio()}>Portfolio <span ref={arrow} ><MdArrowForwardIos/></span> </div>
                                    <div className="innerlist" ref={innerList}>
                                    {React.Children.toArray(mainData.map((elem:any)=><div className="listItems" onClick={()=>buttons("PortfolioFilter",elem)} >{elem.title}</div>))}
                                    </div>
                                    <div className="list" onClick={()=>Contact()}>Contact us</div>
                                </div>
                            </div>
                        </div>
                </div>
                <button className="fancy-burger" ref={btn} onClick={()=>openBurger()}>
                    <span className="rectangle rectangle--top rectangle--small"></span>
                    <span className="rectangle rectangle--middle"></span>
                    <span className="rectangle rectangle--bottom rectangle--small"></span>
                </button>
    </div>
  )
}

export default BurgerMenu