import React, { useContext } from 'react'

import {IoHomeOutline} from 'react-icons/io5'
import {TiThMenuOutline} from 'react-icons/ti'
import {RiArrowGoBackLine} from 'react-icons/ri'
import {FiPhoneCall} from 'react-icons/fi'

import "./navigationButton.scss"
import DataContext from '../../Context/DataContext';
{/* <MadeOfIdea/> */}
const NavigationButtons = () => {
  const data = useContext(DataContext);
  const popUpDiv = data.popUpDiv;
  const setPopUpDiv = data.setPopUpDiv;
  const contactToMenu = data.contactToMenu;
  const setContactToMenu = data.setContactToMenu;
  const setSelectedData = data.setSelectedData;
  const setSwiperToSingleProps = data.setSwiperToSingleProps;
  const swiperToSingleProps = data.swiperToSingleProps;
  const swiperMainController = data.swiperMainController;
  const setOneSubCatagory = data.setOneSubCatagory;
  const setActiveDiv = data.setActiveDiv;
  const restSearch = data.restSearch;

  // Swiper to single Catagory
    const swiperToSingle = (divTo:string,divFrom:string) =>{
    const divs = document.querySelector<HTMLInputElement>(`#${divFrom}`)
    setTimeout(() => {
    if (divs) {
      const divInner = document.querySelector<HTMLInputElement>(`#${divTo}`)
      if (divInner) {
        divInner.style.display = "flex"
      }
      setTimeout(() => {
        divInner?.classList.remove('close')
        setTimeout(() => {
          divs?.classList.add('close')
          setTimeout(() => {
                divs.style.display = "none"
                setSwiperToSingleProps(false)
                setSelectedData({})
          }, 10);
        }, 10);
      }, 10);
      setPopUpDiv(divTo)
    }
  }, 10);
  }

  const removeContact = () =>{
    if (!popUpDiv) {
      swiperMainController("enable")
    }
    const contactToMenuDiv = document.querySelector<HTMLInputElement>(`#${contactToMenu}`)
    if (contactToMenuDiv) {
      contactToMenuDiv?.classList.add('close')
        setTimeout(() => {
          contactToMenuDiv.style.display = "none"
      }, 500);
      setContactToMenu("")
    }
  }
      // When the user clicks on the button, scroll to the top of the document
const GotoHome = () => {
  restSearch();
    if(contactToMenu !== "") {
    removeContact()
  }
  if (popUpDiv!=="") {
    swiperMainController("enable")
    const divs = document.querySelector<HTMLInputElement>(`#${popUpDiv}`)
    if (divs) {
      divs?.classList.add('close')
      setTimeout(() => {
        divs.style.display = "none"
        const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
        backdiv?.classList.remove('close')
        setOneSubCatagory([])
        setActiveDiv("")
    }, 300);
    }
    setPopUpDiv("")
  }
    swiperMainController("prev")
}
  
const backButton = () => {
  restSearch();
  if(contactToMenu !== "") {
    removeContact();
  }else{
  if (swiperToSingleProps) {
    swiperToSingle("MadeOfIdea","PortfolioFilter");
  }else{
    if (popUpDiv!=="") {
      swiperMainController("enable")
      const divs = document.querySelector<HTMLInputElement>(`#${popUpDiv}`)
      if (divs) {
      divs?.classList.add('close')
      setTimeout(() => {
        divs.style.display = "none"
        const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
        backdiv?.classList.remove('close')
        setPopUpDiv("")
        setOneSubCatagory([])
    }, 100);
  }
  setActiveDiv("")
}else{
  swiperMainController("prev")
  }
}
  }
}
  

const contactMenu = (divName:string) =>{
  restSearch();
  if (contactToMenu !== divName) {
  if(contactToMenu !== "") {
    removeContact()
  }
  swiperMainController("disable")
  setTimeout(() => {
  const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
  if (divs) {
      divs.style.display = "flex"
    }
    setTimeout(() => {
      divs?.classList.remove('close')
  }, 100);
  setContactToMenu(divName)
  }, 100);
}
}

  return (
    
    <div className='navigationButtons' >
        <span className="line"></span>
            <button onClick={() =>GotoHome()} ><IoHomeOutline/></button>
            <button onClick={() =>contactMenu(`NeedToHear`)} className={contactToMenu==="NeedToHear"?"active":""}><TiThMenuOutline/></button>
            <button onClick={() =>contactMenu(`ContactUs`)} className={contactToMenu==="ContactUs"?"active":""}><FiPhoneCall/></button>
            <button onClick={() =>backButton()}><RiArrowGoBackLine/></button>
        <span className="line"></span>
    </div>

  )
}

export default NavigationButtons