import React, { useContext, useRef } from 'react'
import './swiperSection.scss'

import {TiThMenuOutline} from 'react-icons/ti'
import {RiArrowGoBackLine} from 'react-icons/ri'
// import {NavigationButtons,CreativitySwiper} from "../../Components"
import NavigationButtons from "../../Components/NavigationButtons/NavigationButtons"
import CreativitySwiper from "../../Components/CreativitySwiper/CreativitySwiper"
import MadeOfIdea from '../MadeOfIdea/MadeOfIdea'
import PortfolioFilter from '../PortfolioFilter/PortfolioFilter'
import ContactUs from '../ContactUs/ContactUs'
import NeedToHear from '../NeedToHear/NeedToHear'
import DataContext from '../../Context/DataContext';
import PopUpDiv from '../../Components/PopUpDIv/PopUpDiv'
import BurgerMenu from '../../Components/BurgerMenu/BurgerMenu'
const SwiperSection = () => {

  const data = useContext(DataContext);
  const popUpDiv = data.popUpDiv;
  const setPopUpDiv = data.setPopUpDiv;
  const selectedData = data.selectedData;
  const contactToMenu = data.contactToMenu;
  const setContactToMenu = data.setContactToMenu;
  const setSelectedData = data.setSelectedData;
  const setSwiperToSingleProps = data.setSwiperToSingleProps;
  const swiperToSingleProps = data.swiperToSingleProps;
  const swiperMainController = data.swiperMainController;
  const setOneSubCatagory = data.setOneSubCatagory;
  const navigationWidth = useRef<HTMLDivElement>(null);

  // When the user clicks on the button, scroll to the top of the document
  const removeContact = () =>{
    if (!popUpDiv) {
      swiperMainController("enable")
    }
  const contactToMenuDiv = document.querySelector<HTMLInputElement>(`#${contactToMenu}`)
  if (contactToMenuDiv) {
    contactToMenuDiv?.classList.add('close')
      setTimeout(() => {
        contactToMenuDiv.style.display = "none"
    }, 500);
    setContactToMenu("")
  }
}
// Swiper to single Catagory
const swiperToSingle = (divTo:string,divFrom:string) =>{
  const divs = document.querySelector<HTMLInputElement>(`#${divFrom}`)
  setTimeout(() => {
  if (divs) {
    const divInner = document.querySelector<HTMLInputElement>(`#${divTo}`)
    if (divInner) {
      divInner.style.display = "flex"
    }
    setTimeout(() => {
      divInner?.classList.remove('close')
      setTimeout(() => {
        divs?.classList.add('close')
        setTimeout(() => {
              divs.style.display = "none"
              setSwiperToSingleProps(false)
              setSelectedData({})
              setPopUpDiv("")
              setOneSubCatagory([])
        }, 10);
      }, 10);
    }, 10);
    setPopUpDiv(divTo)
  }
}, 10);
}


const backButton = () => {

  if(contactToMenu !== "") {
    removeContact()
  }else{
  if (swiperToSingleProps) {
    swiperToSingle("MadeOfIdea","PortfolioFilter");
  }else{
    if (popUpDiv!=="") {
      swiperMainController("enable")
      const divs = document.querySelector<HTMLInputElement>(`#${popUpDiv}`)
      if (divs) {
      divs?.classList.add('close')
      setTimeout(() => {
        divs.style.display = "none"
        const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
        backdiv?.classList.remove('close')
    }, 100);
  }
  setPopUpDiv("")
}else{
  swiperMainController("prev")
  }
}
  }
}
     

const contactMenu = (divName:string) =>{
  if (contactToMenu !== divName) {
  if(contactToMenu !== "") {
    removeContact()
  }
  swiperMainController("disable")
  setTimeout(() => {
  const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
  if (divs) {
      divs.style.display = "flex"
    }
    setTimeout(() => {
      divs?.classList.remove('close')
  }, 100);
  setContactToMenu(divName)
  }, 100);
}
}

  return (
        <div className="SwiperSection">
        <div className="topNavigation" ref={navigationWidth} >
          <button onClick={backButton} className="leftbutton" ><RiArrowGoBackLine/></button>
          <div className="burgerMenu">
            <BurgerMenu navigationWidth={navigationWidth} />
          </div>

          {/* <button onClick={() =>contactMenu(`NeedToHear`)}><TiThMenuOutline/></button> */}
          {/* <button onClick={() =>contactMenu(`NeedToHear`)}><TiThMenuOutline/></button> */}
        </div>
        <div className="left">
          <NavigationButtons/>
        </div>
        <div className="right">
          <PopUpDiv/>
          <NeedToHear/>
          <ContactUs/>
          {selectedData.title&&<PortfolioFilter/>}
          <MadeOfIdea/>
          <CreativitySwiper/>
        </div>
        </div>
  )
}

export default SwiperSection