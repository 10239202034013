import React, { useContext, useEffect } from 'react'
import InnerSwiper from './InnerSwiper'
import Swiper from 'swiper';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';
import "./creativitySwiper.scss"
import DataContext from '../../Context/DataContext';

// import required modules
import { Navigation,Pagination,Scrollbar,Keyboard,Mousewheel,Autoplay,EffectFade,EffectCoverflow  } from 'swiper';

const CreativitySwiper = () => {
  const contextData = useContext(DataContext);
  const mainData = contextData.mainData;

useEffect(() => {
    const swiper = new Swiper('#creativitySwiper', {
      modules:[Navigation,Scrollbar,Keyboard,Autoplay,EffectCoverflow,EffectFade ],
          slidesPerView:1,
          centeredSlides:true,
          keyboard: {
              enabled: true,
              onlyInViewport: false,
            },
          allowTouchMove:false,
          navigation: {
              nextEl: ".creativitySwiper-next-button",
              prevEl: ".creativitySwiper-prev-button"
          },
          // autoplay: {
          // 	delay: 15 * 1000,
          // 	disableOnInteraction: false
          //   },
          speed: 1000,
          spaceBetween: 6,
          loop:true,
          // effect: 'coverflow',
          // coverflowEffect: {
          //   rotate: 30,
          //   slideShadows: false,
          // },
          // effect: 'fade',
          // fadeEffect: {
          //   crossFade: true
          // },
          effect: "fade",
          virtualTranslate: true,
          fadeEffect: {
            crossFade: true
          },
          // loop: "infinite",
          // pagination: {
          // 	el: ".swiper-pagination",
          // 	type: "fraction",
          // 	}
    })
  },[])
  
  return (
    <div className='CreativitySwiper' >
    <div id="creativitySwiper">
        <div className="swiper-wrapper">
        { React.Children.toArray(
          mainData.map((elem:any)=>{
            return(
            <div className="swiper-slide" data-hash="slide1">
              <InnerSwiper data={elem} />
            </div>
            )
          })
        )
        }
        </div>
      </div>
    </div>
  )
}

export default CreativitySwiper