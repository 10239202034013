import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './scroll.scss';
import App from './App';
import DataState from './Context/DataState';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <DataState>
      <App />
    </DataState>
  // </React.StrictMode>
  );
  
  // root.render(
  // <DataState>
  //   <React.StrictMode>
  //   <App />
  // </DataState>
//   </React.StrictMode>
//   );