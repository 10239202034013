import React, { useEffect, useRef } from 'react'
import './navbar.scss'
import logo from '../../Assets/Images/logo.svg';
import {Power4} from 'gsap';


interface props {
    timeline: gsap.core.Timeline;
    navRef: React.RefObject<HTMLDivElement>;
    leftDivRef: React.RefObject<HTMLDivElement>;
    rightDivRef: React.RefObject<HTMLDivElement>;
    logoRef: React.MutableRefObject<null>
    timelineTimings: {
        logoDuration: number;
        logodelay: number;
        logoEyeCrossFade: number;
        eyeDelay: number;
        circleDelay: number;
        lastDelay: number;
    }
}

const Navbar = ({timeline,navRef,leftDivRef,rightDivRef,logoRef,timelineTimings }:props) => {
    // const navRef = useRef<HTMLDivElement>(null);
    // const leftDivRef = useRef<HTMLDivElement>(null);
    // const rightDivRef = useRef<HTMLDivElement>(null);
    // const logoRef = useRef(null);
    // const timeline = gsap.timeline();

    
    const tranisionEffect = ()=>{

        const {logoDuration,logodelay ,logoEyeCrossFade} = timelineTimings
        timeline
        // Logo-Big #2
        .from(
            logoRef.current, {
                duration: logoDuration,
                // opacity: 1, 
                height:"0%",
                ease: Power4.easeInOut,
            }
        )
                                        //#3
        // Logo-Opacity #3
        .to(
            logoRef.current, {
                duration: logoEyeCrossFade,
                opacity: .3, 
                delay:logodelay,
                ease: Power4.easeInOut,
            }
        )
    }
    useEffect(() => {
        tranisionEffect();
            // eslint-disable-next-line 
    }, [])

    return (
    <nav ref={navRef}>
        <div className="left" ref={leftDivRef}>
        <img src={logo} className="logo" alt="logo" ref={logoRef}/>
        </div>
        {/* <div className="right" ref={rightDivRef} >
            <a href="#">Website</a>
        </div> */}
    </nav>
  )
}

export default Navbar