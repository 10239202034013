import React, { useContext } from 'react'
import { HiExternalLink } from "react-icons/hi";
import DataContext from '../../Context';
const Box = ({elm}:any) => {
  
  const contextData = useContext(DataContext);
  const setPreviewPopUpData = contextData.setPreviewPopUpData;
  const openPopUp = () =>{
    setPreviewPopUpData(elm)
    setTimeout(() => {
    const divs = document.querySelector<HTMLInputElement>(`#PreviewPopUpDiv`)
    if (divs) {
        divs.style.display = "flex"
      }
      setTimeout(() => {
        divs?.classList.remove('close')
    }, 100);
    }, 100);
  }


  if (elm.dataType==="img") {return (
      <div className="box img" onClick={()=>openPopUp()}>
        <div className='hover'>Click to full view</div>
        <img src={elm.url} alt="image" />
      </div>
      )}
  if (elm.dataType==="video") {return (
      <div className="box img" onClick={()=>openPopUp()}>
        {/* <video src={elm.url} autoPlay controls muted loop></video> */}
        <div className='hover'>Click to full view</div>
        <video src={elm.url} autoPlay muted loop></video>
      </div>
      )}
  if (elm.dataType==="site") {return (
      <a href={elm.siteUrl} target="_blank"  className="box img">
        <div className='hover'>
          <div>
          Click here to visit website<HiExternalLink className='span'/>
          </div>
          </div>
        <img src={elm.url} alt="image" />
      </a>
      )}
  return(
    <></>
  )


  // if(elm.dataType==="video") return <div className="box img"><img src={elm.url} alt="image" /></div>
  // if(elm.dataType==="img") return <div className="box img"><img src={elm.url} alt="image" /></div>
}

export default Box