import React, { useContext } from 'react'
import "./madeOfIdea.scss";
import data from "../../Assets/data.json";
import DataContext from '../../Context/DataContext';

const MadeOfIdea = () => {
  const contextData = useContext(DataContext);
  const setPopUpDiv = contextData.setPopUpDiv;
  const setSelectedData = contextData.setSelectedData;
  const setSwiperToSingleProps = contextData.setSwiperToSingleProps;
interface swiperToSingle {
  divFrom:string,
  divTo:string,
  data:any
}

// Swiper to single Catagory
  const swiperToSingle = ({divTo,divFrom,data}:swiperToSingle) =>{
    setSelectedData(data)
  const divs = document.querySelector<HTMLInputElement>(`#${divFrom}`)
  setTimeout(() => {
  if (divs) {
    const divInner = document.querySelector<HTMLInputElement>(`#${divTo}`)
    if (divInner) {
      divInner.style.display = "flex"
    }
    setSwiperToSingleProps(true)
    setTimeout(() => {
      divInner?.classList.remove('close')
      setTimeout(() => {
        divs?.classList.add('close')
        setTimeout(() => {
              divs.style.display = "none"
        }, 10);
      }, 10);
    },10);
    setPopUpDiv(divTo)
  }
},10);
}
  return (
    <div id='MadeOfIdea' className='close'>
        <div className="right">
            <div className="small">Portfolio</div>
            <div className="heading">We are made of ideas</div>
            <div className="middle">
            <div className="title">Lorem ipsum dolor sit ame!</div>
            <div className="details">We are a customer-focused organization that considers how to make our clients happy and satisfied with our combined efforts and outcomes. Creating digital domination by combining the finest of technology with experienced knowledge to run your organization. Our team is dedicated to communicating the most effective internet marketing tactics to help your business achieve revenue-generating goals.</div>
            </div>
            <div className="lower">
            <div className="container">
            { React.Children.toArray(
              data.map((elem)=>{
                return(
                  <div className="box" onClick={()=>swiperToSingle({divFrom:"MadeOfIdea",divTo:"PortfolioFilter",data:elem})}>
                  <div className="image">
                    <img src={elem.mainImg} alt="img" />
                  </div>
                  <div className="title">{elem.title}</div>
                  </div>
                )
              })
            )}
            </div>
            </div>
        </div>
    </div>
  )
}

export default MadeOfIdea