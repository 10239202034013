import React, { useContext } from 'react'
import "./popUpDiv.scss"
import { HiOutlineX } from "react-icons/hi";
import DataContext from '../../Context';

const PopUpDiv = () => {
    const contextData = useContext(DataContext);
    const previewPopUpData = contextData.previewPopUpData;
    const setPreviewPopUpData = contextData.setPreviewPopUpData;
    const none = () => {}
    const removePopUp = () => {
        const divs = document.querySelector<HTMLInputElement>(`#PreviewPopUpDiv`)
        if (divs) {
          divs?.classList.add('close')
          setTimeout(() => {
            divs.style.display = "none"
            setPreviewPopUpData({})
        }, 300);
        }
      }
  return (
    // <div id='PreviewPopUpDiv' className='close' onClick={()=>removePopUp()}>
    <div id='PreviewPopUpDiv' className='close'>
        <div className="cross" onClick={()=>removePopUp()}><HiOutlineX/></div>
        <div className="previewer" onClick={()=>none()}>
            {previewPopUpData.dataType==="img"&&<img src={previewPopUpData.url} alt="Image" />}
            {previewPopUpData.dataType==="video"&&<video src={previewPopUpData.url}  controls autoPlay loop></video>}
            
            {/* <img src="https://firebasestorage.googleapis.com/v0/b/h3a-portfolio.appspot.com/o/Portfolio%2Fdata%2FSocial%20Media%20Marketing%2FFestival%20Post%2F2.png?alt=media&token=5a749e9f-c82b-4c6a-bf59-59e683f32a7e" alt="image" /> */}
            {/* <video src="https://firebasestorage.googleapis.com/v0/b/h3a-portfolio.appspot.com/o/Portfolio%2Fdata%2FGraphic%20Designing%2FBanner%2F3.mp4?alt=media&token=a7a7dc06-d3e6-4a3b-a67d-1d6ab50cf499" controls autoPlay loop ></video> */}
        </div>
    </div>
  )
}

export default PopUpDiv