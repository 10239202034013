import React from 'react'
import './socialmedia.scss'
import {FiTwitter,FiLinkedin,FiFacebook,FiInstagram} from 'react-icons/fi'

interface props {
  innerLowerLeft: React.RefObject<HTMLDivElement>
}
const SocialMediaDiv = ({innerLowerLeft}:props) => {
  return (
    <div className='socialMediaDiv' ref={innerLowerLeft} >
      <span className="line"></span>
      <a href="https://mobile.twitter.com/anuragy88424077" target="blank" ><FiTwitter/></a>
      <a href="https://in.linkedin.com/in/h3a-bizsol" target="blank" ><FiLinkedin/></a>
      <a href="https://m.facebook.com/101721658922835/" target="blank" ><FiFacebook/></a>
      <a href="https://www.instagram.com/h3abizsol/" target="blank" ><FiInstagram/></a>
      <span className="line"></span>
    </div>
  )
}

export default SocialMediaDiv