import React, { useContext, useEffect } from 'react'
import Swiper from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';
import "./swiper.scss";

// import required modules
import { Navigation,Scrollbar,Keyboard,Autoplay,EffectFade,EffectCoverflow } from 'swiper';

import AppDevLarge from "./Images/AppDevLarge.png";
import LogoLarge from "./Images/LogoLarge.png";
import SocialMediaLarge from "./Images/SocialMediaLarge.png";
import UiUxLarge from "./Images/UiUxLarge.png";
import WebDevLarge from "./Images/WebDevLarge.png";
import DataContext from '../../Context/DataContext';
import data from "../../Assets/data.json";

const BelowSwiper = () => {
  const contextData = useContext(DataContext);
  const setPopUpDiv = contextData.setPopUpDiv;
  const setSelectedData = contextData.setSelectedData;
  const swiperMainController = contextData.swiperMainController;

  // let title = data.map((elm:any)=> elm.title)
  // console.log(title);
  // let categorys = data.map((elm:any)=> elm.category)
  // let categorys = data.map((elm:any)=> elm.toLowerCase().indexOf(query.toLowerCase()) !== -1)
  // console.log(categorys);
  // let categorysReduce = categorys.reduce((total:any,elm:any)=> [...elm, ...total])
  // console.log(categorysReduce);
  const buttons = (divName:string,filter:string) =>{
    const [datainner] =data.filter((elm)=>elm.title===filter)
    setSelectedData(datainner)
    setTimeout(() => {
      const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
      if (divs) {
        divs.style.display = "flex"
      }
      swiperMainController("next")
      setTimeout(() => {
        swiperMainController("disable")
        divs?.classList.remove('close')
      const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
      backdiv?.classList.add('close')
    }, 100);
    setPopUpDiv(divName)
    }, 100);
  }

useEffect(() => {

    new Swiper('#swiperCircle', {
      modules:[Navigation,Scrollbar,Autoplay,EffectCoverflow,EffectFade ],
          slidesPerView:1,
          centeredSlides:true,
          allowTouchMove:false,
          navigation: {
              nextEl: ".swiper-next-button",
              prevEl: ".swiper-prev-button"
          },
         spaceBetween: 5,
          loop:true,
          effect: "fade",
          virtualTranslate: true,
          fadeEffect: {
            crossFade: true
          },
    })
  },[])
  
  return (
    <>
    
    <div className="swiperCircle" id='swiperCircle'>
        <div className="swiper-wrapper">
          <div className="swiper-slide" data-hash="slide1">
            <div className="box AppDevLarge" onClick={()=>buttons("PortfolioFilter","Mobile Application")}>
              <img src={AppDevLarge} alt="AppDevLarge" />
            </div>
          </div>
          <div className="swiper-slide" data-hash="slide2">
            <div className="box LogoLarge" onClick={()=>buttons("PortfolioFilter","Logo Designing")}>
              <img src={LogoLarge} alt="LogoLarge"  />
            </div>
          </div>
          <div className="swiper-slide" data-hash="slide3">
            <div className="box SocialMediaLarge" onClick={()=>buttons("PortfolioFilter","Social Media Marketing")}>
              <img src={SocialMediaLarge} alt="SocialMediaLarge" />
            </div>
          </div>
          <div className="swiper-slide" data-hash="slide4">
            <div className="box UiUxLarge" onClick={()=>buttons("PortfolioFilter","UX/ UI Design")}>
              <img src={UiUxLarge} alt="UiUxLarge" />
            </div>
          </div>
          <div className="swiper-slide" data-hash="slide5">
            <div className="box WebDevLarge" onClick={()=>buttons("PortfolioFilter","Website Development")}> 
              <img src={WebDevLarge} alt="WebDevLarge" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BelowSwiper