import React, { useContext, useEffect } from 'react'
import {AiOutlineLeft,AiOutlineRight  } from "react-icons/ai";
import Swiper from 'swiper';
import data from "../../Assets/data.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';

import DataContext from '../../Context';

// import required modules
import { Navigation,Pagination,Scrollbar,Keyboard,Mousewheel,Autoplay,EffectFade,EffectCoverflow  } from 'swiper';

const NeedToHearSwiper = () => {

  const contextData = useContext(DataContext);
  const contactToMenu = contextData.contactToMenu;
  const setContactToMenu = contextData.setContactToMenu;
  const popUpDiv = contextData.popUpDiv;
  const swiperMainController = contextData.swiperMainController;
  const setSelectedData = contextData.setSelectedData;
  const setPopUpDiv = contextData.setPopUpDiv;
  const setOneSubCatagory = contextData.setOneSubCatagory;
  const setActiveDiv = contextData.setActiveDiv;

  const toFilter = (elem:string,option:string) =>{
    const [newData] = data.filter((elm)=>elm.title===elem)
    const divName = "PortfolioFilter"

    if (!popUpDiv) {
      swiperMainController("enable")
    }
    const contactToMenuDiv = document.querySelector<HTMLInputElement>(`#${contactToMenu}`)
    if (contactToMenuDiv) {
      contactToMenuDiv?.classList.add('close')
//ADD
      if (option) {
        let [oneCategory] = newData.categorys.filter((elm:any)=>elm.title===option).map((elm:any)=> elm.category)
        if (oneCategory[0]===undefined) {
          setOneSubCatagory(null)
        }else{
          setOneSubCatagory(oneCategory);
        }
        setActiveDiv(option)
      }
      setSelectedData(newData)
      swiperMainController("disable");
      setTimeout(() => {
      const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
      if (divs) {
        divs.style.display = "flex"
      }
      setTimeout(() => {
        divs?.classList.remove('close')
        const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
        backdiv?.classList.add('close')
      }, 100);
      setPopUpDiv(divName)
      }, 100);
//ADD
        setTimeout(() => {
          contactToMenuDiv.style.display = "none"
      }, 500);
      setContactToMenu("")
    }
  }
    
    useEffect(() => {
        const swiper = new Swiper('#needToHearSwiperContainer', {
            modules:[Navigation,Scrollbar,Keyboard,Autoplay,EffectCoverflow,EffectFade,Pagination ],
            speed: 400,
                        //   centeredSlides:true,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            navigation: {
                nextEl: ".needToHearSwiperContainer-next-button",
                prevEl: ".needToHearSwiperContainer-prev-button"
            },
            loop:true,
            slidesPerView: 1,
            spaceBetween: 10,
            breakpoints: {
              // when window width is >= 320px
              550: {
                slidesPerView: 2,
                spaceBetween: 10
              },
              // when window width is >= 480px
              850: {
                slidesPerView: 3,
                spaceBetween: 10
              },
              // when window width is >= 640px
              1080: {
                slidesPerView: 4,
                spaceBetween: 30
              }
            }
          });
      },[])
      
  return (
    <div className="needToHearSwiperContainer"  >
        <div className="button"><div className="iconInner needToHearSwiperContainer-prev-button"><AiOutlineLeft/></div></div>
        <div className="middle">
        <div id="needToHearSwiperContainer" className='swiper'>
        <div className="swiper-wrapper">
        { React.Children.toArray(
            data.map((elm ,i)=>{
              return(
                <>
                <div className="swiper-slide" data-hash={"slide"+(i+1)}>
                <div className="container">
                  <div className="box">
                    <div className="title" onClick={()=>toFilter(elm.title,"")} >{elm.title}</div>
                    <div className="details">
                      <ul>
                        {React.Children.toArray(
                        elm.categorys.map((elm:any)=> elm.title).map((option)=><li>
                          <div className="option" onClick={()=>toFilter(elm.title,option)} >
                          {option}
                          </div>
                          </li>))}
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
            </>
              )
            }))
          }
        </div>
      </div>
        </div>
        <div className="button"><div className="iconInner needToHearSwiperContainer-next-button"><span><AiOutlineRight/></span></div></div>
    </div>
  )
}

export default NeedToHearSwiper