import React, { useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
// import Cursor from './Components/Cursor/Cursor';
// import {Home,ContactUs, NeedToHear, PortfolioFilter, MadeOfIdea} from './Pages';

// import Swiper from './Components/Swiper/Swiper';
import {Swiper} from './Components/index'
import Home from './Pages/Home/Home';
import Home2 from './Pages/Home/Home2';
import Test from './Pages/Test/Test';
// import Swiper2 from './Components/Swiper2/Swiper2';
function App() {

    

  return (
    <div className="App">
      {/* <Cursor/> */}
      {/* <Test/> */}
      {/* <Home/> */}
      <Router>
      <Routes>
      {/* <Route path="*" element={<Home4/>} /> */}
      <Route path="*" element={<Home/>} />
      </Routes>
      </Router>
      {/* <ContactUs/> */}
    </div>
  );
}

export default App;
