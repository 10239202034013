import React, { useContext } from 'react'
import {HiArrowLeft,HiArrowRight} from 'react-icons/hi'
// import {KnowMoreButton} from "../";
import KnowMoreButton from "../KnowMoreButton/KnowMoreButton";
import DataContext from '../../Context/DataContext';
interface props {
  data :{
    title:string,
    details:string,
    mainImg:string,
    options:string[]
  }
}


const InnerSwiper = ({data}:props) => {
  const contextData = useContext(DataContext);
  const setPopUpDiv = contextData.setPopUpDiv;
  const setSelectedData = contextData.setSelectedData;
  const swiperMainController = contextData.swiperMainController;

  const buttons = (divName:string) =>{
    setSelectedData(data)
    swiperMainController("disable");
    setTimeout(() => {
    const divs = document.querySelector<HTMLInputElement>(`#${divName}`)
    if (divs) {
      divs.style.display = "flex"
    }
    setTimeout(() => {
      divs?.classList.remove('close')
      const backdiv = document.querySelector<HTMLInputElement>(`.CreativitySwiper`)
      backdiv?.classList.add('close')
    }, 100);
    setPopUpDiv(divName)
    }, 100);
  }
  return (
    <div className='InnerSwiper'>
      <div className="left">
        <div className="upper">
          <div className="static"> <li>the secret to creativity</li> </div>
          <div className="title">{data.title} </div>
        </div>
        <div className="middle">
        <div className="outerImg">
          <div className="img">
            <div className="imageBorder">
            </div>
            <img src={data.mainImg} alt="img" />
          </div>
        </div>
          <div className="button">
            <div className="next creativitySwiper-next-button">
              <button><HiArrowLeft/></button>
            </div>
            <div className="prev creativitySwiper-prev-button">
              <button><HiArrowRight/></button>
          </div>
        </div>
      </div>
        <div className="lower">
          <div className="details">{data.details}</div>
          <div className="borrow">Borrow our creativity</div>
          <div className="button">
          <KnowMoreButton buttons={buttons}  />
          </div>
        </div>
      </div>
      <div className="right">
        <div className="outerImg">
          <div className="img">
            <div className="imageBorder">
            </div>
            <img src={data.mainImg} alt="img" />
          </div>
        </div>
          <div className="button">
            <div className="next creativitySwiper-next-button">
              <button><HiArrowLeft/></button>
            </div>
            <div className="prev creativitySwiper-prev-button">
              <button><HiArrowRight/></button>
          </div>
        </div>
      </div>
      </div>
  )
}

export default InnerSwiper