import React from 'react'

const Info = () => {
  return (
    <div className="contactInfo">
    <div className="conteactInfoUpper">
      <div className="infoHeading">Contact Info</div>
      <hr />
    </div>
    <div className="detailsDiv">
      <div className="infoTitle">Address</div>
      <div className="infoDetails">
        <a href="https://www.google.com/maps/place/H3A+Business+Solutions+Pvt.+Ltd./@28.5854051,77.3292779,15z/data=!4m5!3m4!1s0x0:0x6764c289e80fa07f!8m2!3d28.5854051!4d77.3292779" rel="noopener noreferrer" target="_blank">
          A-16, Second Floor, Sector 9,<br/>Noida, Uttar Pradesh 201301
        </a>
      </div>
      <div className="infoTitle">Email</div>
      <div className="infoDetails">
      <a href="mailto:contact@h3abizsol.com " rel="noopener noreferrer" target="_blank">
        contact@h3abizsol.com
        </a>
        </div>
      <div className="infoTitle">Phone</div>
      <div className="infoDetails">
        <a href="tel:+919879037614">
        +91-9879037614
        </a>
      </div>
    </div>
  </div>
  )
}

export default Info