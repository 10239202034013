import React, { useEffect, useState } from 'react'
import './rotaterNine.scss'
import gsap from 'gsap';
import {HiArrowSmUp} from 'react-icons/hi'
import BelowSwiper from '../Swiper/BelowSwiper';
//Image
import AppDevSmall from './Image/AppDevSmall.png';
import LogoSmall from './Image/LogoSmall.png';
import SocialMediaSmall from './Image/SocialMediaSmall.png';
import UiUxSmall from './Image/UiUxSmall.png';
import WebDevSmall from './Image/WebDevSmall.png';
const Rotater = () => {
    const change = gsap.timeline();
    const [roatationNumber, setRoatationNumber] = useState(-8)
    const [roatationInner, setRoatationInner] = useState(30)
    const [locker, setLocker] = useState("Unlocked")
    // const [roatationInner, setRoatationInner] = useState(199)
    // const [roatationInner, setRoatationInner] = useState(195)
    // const [roatationInner, setRoatationInner] = useState(166-33)
    const [stop, setStop] = useState(false)


    // useEffect(() => {
    // var number = roatationNumber

    // const rotation  = setInterval(() => {
    //     const circle:HTMLElement|null = document.querySelector('.circle');
    //     if(circle){
    //         const circleStyle = circle.style
    //         circleStyle.transform = `rotate(${number+36}deg)`;
    //         number = number + 36
    //         setRoatationNumber(number+36)
    //     }
    // }, 1000*5);
    // return () => {
    //         setStop(false);
    //         clearInterval(rotation);
    //     }
    // }, [stop])
    const onLeft = ()=>{
        setLocker("Locked")
        const circle:HTMLElement|null = document.querySelector('.circle');
        if(circle){
            for (let index = 1; index <= 9; index++) {                    
                // circle.getElementsByTagName('div')[(index*2)-1 ].style.transform = `rotate(${(roatationInner + (6-index)*40)}deg)`;
                // circle.getElementsByTagName('div')[(index*2)-1 ].style.transform = `rotate(${roatationNumber - ((40*(index)))}deg)`;
                circle.getElementsByTagName('div')[(index*2)-1 ].style.transform = `rotate(${((roatationInner + (6-index)*40 + 140))}deg)`;

                console.log(roatationNumber + ((40*(10-index))-30));
            }
            setRoatationInner(roatationInner+40)
        
        const circleStyle = circle.style
        circleStyle.transform = `rotate(${roatationNumber-40}deg)`;
        setRoatationNumber(roatationNumber-40)
        setStop(true);
        setTimeout(() => {
            setLocker("Unlocked")
        }, 400);
        }
    }
    const onright = ()=>{
        setLocker("Locked")
        console.log("hello onright",roatationNumber);
        const circle:HTMLElement|null = document.querySelector('.circle');
        if(circle){
        for (let index = 1; index <= 9; index++) {                 
            circle.getElementsByTagName('div')[(index*2)-1 ].style.transform = `rotate(${((roatationInner - (6+index)*40 + 180))}deg)`;
        }
        setRoatationInner(roatationInner-40)
        const circleStyle = circle.style
        circleStyle.transform = `rotate(${roatationNumber+40}deg)`;
        setRoatationNumber(roatationNumber+40)
        setStop(true);
        setTimeout(() => {
            setLocker("Unlocked")
        }, 400);
      }
    }
    
  return (
    <div className='rotater'>
        <div className="circleContainer">
        <div className="circle">
            <div className="box">
                <div className="innerbox AppDevSmall">
                    {/* 1 */}
                    <img src={AppDevSmall} alt="AppDevSmall" />
                </div>
            </div>
            <div className="box">
                <div className="innerbox LogoSmall">
                    {/* 2 */}
                    <img src={LogoSmall} alt="LogoSmall" />
                </div>
            </div>
            {/* <div className="box">
                <div className="innerbox">
                    3
                </div>
            </div> */}
            <div className="box">
                <div className="innerbox UiUxSmall">
                    {/* 4 */}
                    <img src={UiUxSmall} alt="UiUxSmall" />
                </div>
            </div>
            <div className="box">
                <div className="innerbox WebDevSmall">
                    {/* 5                     */}
                    <img src={WebDevSmall} alt="WebDevSmall" />

                </div>
            </div>
            <div className="box">
                <div className="innerbox AppDevSmall">
                    {/* 1 */}
                    <img src={AppDevSmall} alt="AppDevSmall" />
                </div>
            </div>
            <div className="box">
                <div className="innerbox LogoSmall">
                    {/* 2 */}
                    <img src={LogoSmall} alt="LogoSmall" />
                </div>
            </div>
            <div className="box">
                <div className="innerbox SocialMediaSmall">
                    {/* 3 */}
                    <img src={SocialMediaSmall} alt="SocialMediaSmall" />
                </div>
            </div>
            <div className="box">
                <div className="innerbox UiUxSmall">
                    {/* 4 */}
                    <img src={UiUxSmall} alt="UiUxSmall" />
                </div>
            </div>
            <div className="box">
                <div className="innerbox WebDevSmall">
                    {/* 5 */}
                    <img src={WebDevSmall} alt="WebDevSmall" />
                </div>
            </div>
        </div>
        </div>
        <div className="belowDiv">
        <BelowSwiper/>
        </div>
        <div className="navigationBUtton">
            {/* <button className="down button swiper-prev-button" onClick={()=>onright()} disabled={ (roatationNumber >=-24)?true:false} ><HiArrowSmUp/></button>
            <button className="up button swiper-next-button" onClick={()=>onLeft()} disabled={ (roatationNumber <=-168)?true:false} ><HiArrowSmUp/></button> */}
            <button className="down button swiper-prev-button" onClick={()=>onright()} disabled={ (roatationNumber >=-8 || locker === "Locked")?true:false} ><HiArrowSmUp/></button>
            <button className="up button swiper-next-button" onClick={()=>onLeft()} disabled={ (roatationNumber <=(-40*4)-8 || locker === "Locked")?true:false} ><HiArrowSmUp/></button>
       
      </div>
    </div>
  )
}

export default Rotater